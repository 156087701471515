<template>
    <div class="recruitment-container-box" :class="recruitmentbox">
      <!-- 导航栏部分 -->

      <p class="bigTitle">招聘动态</p>
      <van-tabs
        v-model="active"
        class="tab"
        type="card'"
        title-active-color="#00b3be"
      >
        <van-tab>
          <template #title>
            <img src="~@/assets/img/LinkOn.png" v-if="active == 0" /><img
              src="~@/assets/img/LinkOff.png"
              v-else
            />招聘流程 </template
          ><Notice></Notice
        ></van-tab>
        <van-tab>
          <template #title>
            <img src="~@/assets/img/QaOn.png" v-if="active == 1" /><img
              src="~@/assets/img/QaOff.png"
              v-else
            />
            答疑专区
          </template>
          <QuestionAndAnswer></QuestionAndAnswer
        ></van-tab>
      </van-tabs>
    </div>
</template>
<script>
import mobileBanner from "./homeComponents/mobileBanner.vue";

import Notice from "./trendsComponents/Notice.vue";
import QuestionAndAnswer from "./trendsComponents/QuestionAndAnswer.vue";

export default {
  name: "Trends",
  components: { QuestionAndAnswer, Notice, mobileBanner },
  data() {
    return {
      activeNavTab: "0",
      active: 0,
      recruitmentbox: "recruitmentbox",
    };
  },
};
</script>
<style lang="less" scoped>
.recruitment-container-box {
  margin-top: 2.75rem;
  overflow: hidden;
  width: 100%;
  min-height: calc(100% - 346px);
  background-color: rgba(250, 253, 253, 1);

  /deep/ .van-tab {
    background: #ffffff;
    padding: 1.1rem 0.625rem 0.625rem 0.625rem;
    //box-shadow: 0px 1px 4px 0px rgba(45, 81, 128, 0.04);
  }
  img {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: bottom;
  }
  .bigTitle {
    //margin: 1rem auto;
    padding-top: 8px;
    width: 100%;
    max-width: 750px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.75rem;
    height: auto;
    /* identical to box height, or 156% */
    position: fixed;
    top: 2.75rem;
    z-index: 9;
    text-align: center;
    background: #ffffff;
    /* 文字色/标题 */

    color: #040b29;
  }
}
.recruitmentbox {
  background: #ffffff;
}
/deep/ .van-tabs__nav {
  box-shadow: 0px 1px 4px 0px rgba(45, 81, 128, 0.04);
}
/deep/ .van-tabs__wrap {
  position: fixed;
  top: 5.05rem;
  width: 100%;
  max-width: 750px;
  overflow: inherit;
  background: #fff;
  z-index: 9;
}
/deep/ .van-tabs__content {
  padding: 0px 20px;
  margin-top: 7.5rem;
  width: 100%;
}
</style>
