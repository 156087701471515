<template>
  <div>
    <div style="width: 100%">
      <div style="width: 100%; height: 100%; margin-top: 10px">
        <p
          style="
            font-size: 16px;
            font-weight: 400;
            color: #040b29;
            margin: 1.5rem 0 0 0px;
            line-height: 1.5rem;
          "
        >
          招聘公告
        </p>
        <div class="wrap">
          <ul class="flex-v">
            <li
              class="flex tagLi"
              v-for="(item, id) in config().recruitmentAnnouncement"
              :key="id"
              style="margin: 5px 0; cursor: pointer"
              @click="goDetails(item)"
            >
              <span
                :class="{
                  comingSong: !item.mobileLinkUrl && !item.linkUrl,
                }"
                style="color: #040b29; font-size: 12px"
                >{{ item.title }}
              </span>
              <span
                v-if="item.time"
                style="color: #939799; font-size: 12px; font-weight: 400"
                >{{ item.time }} <i class="el-icon-arrow-right"></i
              ></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="recuritProcess">
      <p
        style="
          font-size: 16px;
          font-weight: 400;
          color: #040b29;
          margin: 0.5rem 0 0.75rem 0px;
          line-height: 1.5rem;
        "
      >
        投递流程
      </p>
      <div style="width: calc(100%); height: 100%; padding: 0 0px">
        <img :src="deliverImage" style="width: 100%; height: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notice",
  components: {},
  inject: ["config"],
  data() {
    return {
      deliverImage:
        "https://cdn-public.nio.com/nioappadmin/51P7vZ8fWtkFkvs_qp2WT/944d653b-9a7b-4a1f-afda-2d6715dd4719.jpg",
    };
  },
  methods: {
    // 链接跳转
    goDetails(e) {
      if (e.mobileLinkUrl || e.linkUrl) {
        window.open(e.mobileLinkUrl || e.linkUrl, "_self");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  border-radius: 10px;
  padding: 10px 0px;

  .tagLi {
    justify-content: space-between;
  }

  .infoTag {
    display: inline-block;

    height: 30px;
    border-radius: 2px;
    padding: 0px 8px;
    margin: 0 5px;
    color: #00b3be;
    border: 1px solid #00b3be;
    vertical-align: middle;
    font-size: 12px;
  }

  .comingSong {
    color: #ccc !important;
  }

  .recuritProcess {
    width: 100%;
  }
}
</style>
