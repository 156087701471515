<template>
  <div class="page">
    <div class="q-a">
      <h5 class="q-a-title">{{ title }}</h5>
      <!-- <p class="q-a-desc">{{ title.desc }}</p> -->

      <div class="qa-list-wrap pr">
        <i class="nio-car abs"></i>
        <div v-for="item in dataSource" :key="item.id" ref="anchorDiv">
          <div class="title">{{ item.typeName }}：</div>
          <ul class="q-a-content">
            <li class="q-a-list" v-for="(i, index) in item.list" :key="index">
              <div class="q-desc">
                {{ i.question }}
              </div>
              <div class="a-desc" v-html="i.answer"></div>
            </li>
          </ul>
        </div>
        <i class="team-icon abs"></i>
      </div>
    </div>
    <div class="bg abs"></div>
  </div>
</template>
<script>
import { env } from "@/utils/getEnv.js";
export default {
  name: "QuestionAndAnswer",
  data() {
    return {
      title: "",
      dataSource: [],
    };
  },
  created() {
    fetch(
      `https://static.nio.com/fx-static/4349/fixed/qa.${
        env === "prod" ? 'prod' : "test"
      }.json?t=${Date.now()}`
    )
      .then((r) => r.json())
      .then((res) => {
        this.title = res.title;
        this.dataSource = res.list?.map((item, index) => ({
          ...item,
          id: index + 1 + "",
        }));
      });
  },
  destroyed() {},
  methods: {
    // 锚点跳转
    //
  },
};
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 80px;
  width: 100%;
  .bg {
    width: 100%;

    bottom: 0;
    left: 0;

    background-size: 100% 100%;
  }

  .q-a {
    margin: 0 auto;
    padding-top: 0.375rem;
    width: 100%;
    z-index: 2;

    .title {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #040b29;
      line-height: 1rem;
    }
    .q-a-title {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      line-height: 1.5rem;
      white-space: pre-wrap;
    }
    .q-a-desc {
      font-size: 10px;
      font-weight: 400;
      text-align: center;
      line-height: 1.5rem;
      white-space: pre-wrap;
    }
    .lastInfo {
      margin: -50px 0 0 0 !important;
    }
    .qa-list-wrap {
      margin-top: 1.5rem;
    }
    .q-a-content {
      width: 100%;
      margin: 1rem auto;

      border-radius: 4px;
      padding: 1% 0;
      .q-a-list {
        margin: 0 auto;
        padding: 1rem 0;
        width: 100%;

        .q-desc {
          // display: flex;
          line-height: 1.375rem;
          padding-bottom: 12px;
          font-size: 0.875rem;
          font-weight: 500;
          color: #00bebe;
          word-break: break-all;
        }
        .a-desc {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.375rem;
          word-break: break-all;
          color: rgba(129, 133, 148, 1);
          text-align: justify;
        }
        .q-desc,
        .a-desc {
          padding-left: 24px;
          text-indent: -24px;
        }
      }
      .q-a-list:last-child {
        border-bottom: none;
      }
      .q-a-list-info {
        margin: 0 auto;
        padding: 24px 10px;
        width: 100%;
        font-size: 18px;
        background-color: #fafafa;
      }
    }
  }
}
</style>
